import React from "react"


import { Layout, Hero, About } from "../components/"


const Index: React.FC = () => (
  <Layout>
    <Hero />
    <About />
  </Layout>
)

export default Index
